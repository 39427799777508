import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/Overview')))
);
const Logout = Loader(
  lazy(() => import('src/content/pages/Components/Logout'))
);
const Startups = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/Startups')))
);
const StartupDetails = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/StartupDetails')))
);
const Workbench = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/Workbench')))
);
const TaskDetails = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/Tasks/TaskDetails')))
);
const Programs = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/pages/Components/Programs')))
);

// Profile

const UserProfile = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/applications/Users/profile')))
);
const UserSettings = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/applications/Users/settings')))
);

// Examples

const Crypto = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/dashboards/Crypto')))
);
const Messenger = withAuthenticationRequired(
  Loader(lazy(() => import('src/content/applications/Messenger')))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/startups',
        element: <Startups />
      },
      {
        path: '/startups/:id',
        element: <StartupDetails />
      },
      {
        path: '/programs',
        element: <Programs />
      },
      {
        path: '/workbench',
        element: <Workbench />
      },
      {
        path: '/tasks/:id',
        element: <TaskDetails />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="details" replace />
      },
      {
        path: 'details',
        element: <UserProfile />
      },
      {
        path: 'settings',
        element: <UserSettings />
      }
    ]
  },
  {
    path: 'examples',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: 'status',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="404" replace />
      },
      {
        path: '404',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  }
];

export default routes;
